body {
  font-family: Arial, sans-serif;
  background: #1a1a1a;
  color: white;
  margin: 0;
  padding: 0;
}

html, body {
  touch-action: manipulation;
}

.App {
  font-family: 'Arial', sans-serif;
  color: #333;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: auto;
}

.App-header {
  text-align: center;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-left: 0px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  width: 100%;
}

.App-header h1 {
  cursor: pointer;
  color: 'lightyellow';
  transition: color 0.3s;
  margin: 0;
}

.App-header h1:hover {
  color: #0056b3;
}

.copyright {
  text-align: center;
  margin-top: 40px;
  font-size: 0.9em;
  color: #666;
}

.error-message {
  color: red;
}

.app-grid {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.app-item {
  background-color: #f8f9fa;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.language-switch {
  margin-top: 20px;
  text-align: center;
}

.language-button {
  background-color: 'lightgray';
  color: black;
  border: none;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 60px;
}

.language-button:hover {
  background-color: #0056b3;
}

.link-text-white {
  color: #007BFF;
  text-decoration: none;
  transition: color 0.3s;
}

.link-text-white:hover {
  color: #0056b3;
}
